import React from 'react'

const WebHits = ({ webHits }) => {
    return (
      <div>
        <div id="header" />
        <div className="left" />
        <div className="stuff">
          <br /><br />
          <h1>Resume</h1>
          <h2>Thomas Peters</h2>
          <hr />
          <br />
          <p className="head">Interests</p>
          <ul>
            <li>TODO</li>
            <li>TODO</li>
            <li>TODO</li>
            <li>TODO</li>
          </ul>
          <p className="head">Skills</p>
          <ul>
            <li>TODO</li>
          </ul>
          <p className="head">Education</p>
          <ul>
            <li>TODO</li>
            <li>TODO</li>
            <li>TODO</li>
          </ul>
          <p className="head">Experience</p>
          <ul>
            <li>TODO</li>
            <li>TODO</li>
          </ul>
          <p className="head">Extracurriculars</p>
          <ul>
            <li>TODO</li>
            <li>TODO</li>
            <li>TODO</li>
          </ul>
          <br></br>
          <p className="head">Deploy Source Links</p>
          <ul>
            <li><a href="https://gitlab.com/sirmysterion/cloudresume-frontend">FrontEnd</a></li>
            <li><a href="https://gitlab.com/sirmysterion/cloudresume-backend">BackEnd</a></li>
          </ul>
        </div>
        <div className="right" />

        <div className="card">
          <p><i className="fa fa-user"></i></p>

            <h3>{webHits}</h3>
          
          <p>Visiters</p>
        </div>


        <div id="footer">
          <h2 id="name">Thomas Peters</h2>
        </div>
      </div>




    )
};

export default WebHits