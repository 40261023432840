//import logo from './logo.svg';
import './App.css';

import React, { Component } from 'react'
import WebHits from './components/webHits.js';


class App extends Component {
  render() {
      return (
          <WebHits webHits={this.state.webHits} />
      )
  }

  state = {
      webHits: []
  };

  componentDidMount() {
      fetch('https://api-this-is-thomas.tnepeters.com/resource')
          .then(res => res.json())
          .then((data) => {
              this.setState({ webHits: data })
          })
          .catch(console.log)
  }
}

export default App;